import React, { useEffect, useState, useContext, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { Typography, Select, MenuItem, FormControl, InputLabel, styled } from '@mui/material';

import { useI18n } from '@braincube/i18n';

import { SsoContext, startFetching, stopFetching } from 'App/contexts';
import ButtonsGroupWithHelp from 'components/ButtonsGroupWithHelp';
import { MainSection, MainSectionTitle, MainSectionContent } from 'components/MainSection';
import { me } from 'services/api/sso';

const StyledFormControl = styled(FormControl)(({ theme }) => ({
    width: '100%',
    marginTop: theme.spacing(2),
}));

function AccessList() {
    const { dispatch } = useContext(SsoContext);
    const [access, setAccess] = useState([]);
    const i18n = useI18n();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(startFetching());

        me()
            .then((response) => {
                dispatch(stopFetching());

                if (response.ok) {
                    response.json().then(({ accessList }) => {
                        if (accessList.length === 0) {
                            navigate('/no-access');
                        } else {
                            setAccess(accessList);
                        }
                    });
                } else {
                    navigate('/');
                }
            })
            .catch(() => {
                dispatch(stopFetching());

                navigate('/');
            });
    }, [dispatch, navigate]);

    const handleChange = useCallback(
        (event) => {
            const found = access.find((oneAccess) => oneAccess.product.productId === event.target.value);

            if (found) {
                if (found.product.type === 'braincube') {
                    const url = new URL(`https://${window.BC_API_ENDPOINTS_CONF.cdn}/dashboards`);
                    url.searchParams.append('siteUuid', found.product.site.siteUuid);

                    window.location.assign(url.toString());
                } else {
                    window.location.assign(found.product.url);
                }
            } else {
                navigate('/no-access');
            }
        },
        [access, navigate]
    );

    const handleClick = useCallback(() => {
        navigate('/');
    }, [navigate]);

    return (
        <MainSection>
            <MainSectionTitle>{i18n.t('app.accessList.title')}</MainSectionTitle>
            <MainSectionContent>
                <div>
                    <Typography variant="body2">{i18n.tc('app.accessList.text')}</Typography>
                </div>
                <StyledFormControl>
                    <InputLabel>{i18n.tc('app.accessList.selectLabel')}</InputLabel>
                    <Select
                        data-testid="access-list"
                        value=""
                        label={i18n.tc('app.accessList.selectLabel')}
                        onChange={handleChange}
                    >
                        {access.map((oneAccess) => (
                            <MenuItem key={oneAccess.product.productId} value={oneAccess.product.productId}>
                                {`${oneAccess.product.name} - ${oneAccess.product.type}`}
                            </MenuItem>
                        ))}
                    </Select>
                </StyledFormControl>
                <ButtonsGroupWithHelp onBigButtonClick={handleClick} label={i18n.tc('app.backButton')} />
            </MainSectionContent>
        </MainSection>
    );
}

export default AccessList;
